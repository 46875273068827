import { BrickAltTeaser } from '@amedia/brick-alt-teaser';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import Component from './alt-page.component.svelte';

import { persistPWATest } from '$utils/persistPWA';
import { SvelteElement, defineCustomElements } from 'templating-tool';

const componentName = 'amedia-alt-page';

document.querySelector('amedia-alt-page').innerHTML = '';

export default class AltPage extends SvelteElement {
  constructor(props) {
    super({
      Component,
      options: { ...props, componentName },
    });
  }

  initializePush() {
    Notification.requestPermission().then(function (result) {
      if (result === 'granted') {
        console.log('granted push for pwa');
        const firebaseConfig = {
  "apiKey": "AIzaSyBfQfJtcWCzgYUxFEIoFb-Ahq-uns_DSxY",
  "authDomain": "app-nyheter-plussalt.firebaseapp.com",
  "projectId": "app-nyheter-plussalt",
  "storageBucket": "app-nyheter-plussalt.appspot.com",
  "messagingSenderId": "736951715700",
  "appId": "1:736951715700:web:a5df8623aca027380f0e21",
  "measurementId": "G-5CKH800JLZ"
}; // replaced by esbuild
        const vapidKey = 'BPNUgeae1K_ZrsVEySb39viNd6t45fqJVtg7Dm_g1kmK0wPIaCTZFG2-msDJod0HE_3lGpjSh_vZbvL0IjVzdwM'; // replaced by esbuild
        initializeApp(firebaseConfig);

        const messaging = getMessaging();
        // navigator.serviceWorker.register('/api/mozart/v1/firebase-service-worker.js').then((registration) => {
        navigator.serviceWorker
          .register('/api/mozart/v1/firebase-service-worker.js')
          .then((registration) => {
            console.log(
              'Service worker successfully registered:',
              registration
            );

            console.log('attempting to update service worker');
            registration.update();

            getToken(messaging, {
              serviceWorkerRegistration: registration,
              vapidKey,
            })
              .then((currentToken) => {
                console.log('send me to the server', currentToken);
                fetch(`/api/mozart/v1/register-push?token=${currentToken}`)
                  .then((response) => {
                    response.text().then((text) => {
                      console.log(text);
                    });
                  })
                  .catch((registrationError) => {
                    console.log('failed to register client', registrationError);
                  });
              })
              .catch((tokenError) => {
                console.log('unable to get token', tokenError);
              });
          })
          .catch((serviceWorkerRegistrationError) => {
            console.log(
              'serviceWorkerRegistrationError',
              serviceWorkerRegistrationError
            );
          });
      } else {
        console.log('rejected push for pwa');
      }
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    console.log('connectedCallback');
    persistPWATest();
    if (localStorage.getItem('testpush') === 'true') {
      console.log('initializing push');
      this.initializePush();
    }
  }
}

defineCustomElements({
  'brick-alt-teaser-v5': BrickAltTeaser,
  [componentName]: AltPage,
});
